<template>
  <!-- 404页面 -->
  <div class="exception">
    <!-- 异常内容 -->
    <div class="exception-wrap">
      <!-- 异常图片 -->
      <div class="exception-img">
        <img src="@/assets/image/404.svg" alt="" />
      </div>

      <!-- 异常描述 -->
      <div class="exception-info">
        <div class="exception-status">404</div>
        <div class="exception-describe">
          抱歉哈！您访问的页面不存在或仍在开发中
        </div>
        <div class="exception-btn">
          <el-button
            type="primary"
            icon="el-icon-back"
            round
            @click="$router.go(-1)"
            >返回上一页</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped lang="scss">
/* 异常信息组件 */
.exception {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #eee;

  /* 异常内容 */
  &-wrap {
    margin: auto;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 0px 5px 5px #eee;

    /* 异常图片 */
    .exception-img {
      height: 100%;
      display: flex;
      padding: 15px;

      img {
        width: 300px;
        height: 300px;
        object-fit: contain;
      }
    }

    /* 异常描述 */
    .exception-info {
      flex: 1;
      text-align: center;
      padding: 15px;

      .exception-status {
        color: #333;
        font-size: 65px;
        font-weight: bold;
      }

      .exception-describe {
        color: #666;
        font-size: 15px;
      }

      & > div {
        margin-bottom: 25px;
      }
    }
  }
}
</style>
